import { FunctionComponent, ReactElement, useMemo } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Engine } from 'react-babylonjs';
import { FastBrainTileSelectionLoaderResult } from '@/router/routes/protected/fast-brain';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { ButtonBack, ButtonNext } from '@/components/ui/button';
import BabylonjsLoader from '@/components/feature/babylonjs-loader';
import FastBrainTileScene from '@/views/scenes/FastBrainTileScene';
import {
	useFastBrainSelectTiles,
	useFastBrainTileSelectionData,
	useFastBrainTileSelectionLoader,
	useIsFastBrainTileSelectionDisabled,
} from '@/features/fast-brain/tile-selection/hooks';
import { isCurrentFlowFinished } from '@/lib/utils';
import { Flow } from '@/api/users/types';

const FastBrainTileSelection: FunctionComponent = (): ReactElement => {
	// Scene loader
	const isSceneLoading = useFastBrainTileSelectionLoader();
	const isFlowFinished = isCurrentFlowFinished(Flow.FAST_BRAIN);

	// Extract loader data
	const { tileGroups: tilesInitialData, tileGroupsSelectedTiles: selectedTilesInitialData } =
		useLoaderData() as FastBrainTileSelectionLoaderResult;

	// Get tile selection data: tile groups and selected tiles
	const { tileGroups, tileGroupsSelectedTiles, invalidateSelectedTiles } =
		useFastBrainTileSelectionData({
			tileGroupsInitialData: tilesInitialData,
			tileGroupsSelectedTilesInitialData: selectedTilesInitialData,
		});

	// Extract tile group because fast brain only has one tile group;\
	const tileGroup = useMemo(() => tileGroups[0], [tileGroups]);

	// Perform submit
	const navigate = useNavigate();
	const { mutate: onSubmit, isPending: isTileSelectionPending } = useFastBrainSelectTiles({
		tileGroup,
		onSuccess: () => {
			navigate('../fast-brain/tile-questions/intro');
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			invalidateSelectedTiles();
		},
	});

	const isDisabled = useIsFastBrainTileSelectionDisabled({
		isSceneLoading,
		isTileSelectionPending,
	});

	const controls = (
		<div className="flex flex-row items-center justify-center gap-8">
			<ButtonBack onClick={() => navigate('../fast-brain/tile-selection/intro')} />
			<ButtonNext
				onClick={() => {
					if (!isFlowFinished) onSubmit();
					else navigate('../fast-brain/tile-questions/intro');
				}}
				disabled={isDisabled}
			>
				Continue
			</ButtonNext>
		</div>
	);

	return (
		<div className="flex h-full flex-col bg-neutral-page">
			<Header titleText="Fast Brain" titleLabel="Tile Selection" />
			<div className="relative flex-1 overflow-hidden">
				{isSceneLoading && <BabylonjsLoader />}
				<Engine antialias adaptToDeviceRatio canvasId="babylonjs-canvas">
					<FastBrainTileScene
						tilesGroup={tileGroups}
						selectedTilesGroup={tileGroupsSelectedTiles}
					/>
				</Engine>
			</div>
			<Footer withBoxShadow={true} itemsPosition="center" size="lg" controls={controls} />
		</div>
	);
};

export default FastBrainTileSelection;
