import { RouteObject } from 'react-router-dom';
import PortfolioRecommendationsIntro from '@/views/pages/portfolio-recommendations/intro';
import PortfolioRecommendationsQuestionnaire from '@/views/pages/portfolio-recommendations/questionnaire';
import NextBestActions from '@/views/pages/portfolio-recommendations/output/next-best-actions';
import ClientReport from '@/views/pages/portfolio-recommendations/output/client-report';
import FlowGuard from '../guards/FlowGuard';
import { Flow } from '@/api/users/types';

export const portfolioRecommendationsRoutes: RouteObject[] = [
	{
		path: 'portfolio-considerations/intro',
		element: (
			<FlowGuard
				flow={Flow.PORTFOLIO_RECOMMENDATIONS}
				element={<PortfolioRecommendationsIntro />}
			/>
		),
	},
	{
		path: 'portfolio-considerations/questionnaire',
		element: (
			<FlowGuard
				flow={Flow.PORTFOLIO_RECOMMENDATIONS}
				element={<PortfolioRecommendationsQuestionnaire />}
			/>
		),
	},
	{
		path: 'portfolio-considerations/next-best-actions',
		element: <FlowGuard flow={Flow.PORTFOLIO_RECOMMENDATIONS} element={<NextBestActions />} />,
	},
	{
		path: 'portfolio-considerations/client-report',
		element: <FlowGuard flow={Flow.PORTFOLIO_RECOMMENDATIONS} element={<ClientReport />} />,
	},
];
