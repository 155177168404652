import { AuthUser, Flow } from '@/api/users/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const classNames = (...args: any[]) => args.filter(Boolean).join(' ');

export const CLIENT_ID_KEY = 'clientId';
export const USER_INFO_KEY = 'user';
export const TOKEN_KEY = 'token';

export const getClientId = () => localStorage.getItem(CLIENT_ID_KEY) || '';

export const getUser = () => JSON.parse(localStorage.getItem(USER_INFO_KEY) || '{}') as AuthUser;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY) || '';

export const isCurrentFlowFinished = (flow: Flow) => {
	const user = getUser();
	return user?.clientFlows.find((fl) => fl.flowName === flow)?.status === 'FINISHED';
};

export const renderFormattedText = (text: string) => {
	const modifiedText = text.replace(/^-\s*/, '• ');

	const parts = modifiedText.split(/(\*\*.*?\*\*)/g);
	return parts.map((part, index) => {
		const isBold = part.startsWith('**') && part.endsWith('**');
		const displayText = isBold ? part.slice(2, -2) : part;
		return (
			<span key={index} style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
				{displayText}
			</span>
		);
	});
};
