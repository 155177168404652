import { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';
import PageNotFound from '@/views/pages/page-not-found';
import { Flow } from '@/api/users/types';
import { getUser } from '@/lib/utils';

interface FlowGuardProps {
	flow: Flow;
	element: ReactNode;
}

const FlowGuard: FunctionComponent<FlowGuardProps> = ({ element, flow }): ReactElement => {
	const { clientFlows } = getUser();
	const accessibleFlows = useMemo(() => clientFlows?.map((fl) => fl.flowName) || [], [clientFlows]);

	if (!accessibleFlows.includes(flow)) {
		return <PageNotFound />;
	}

	return <>{element}</>;
};

export default FlowGuard;
