import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { login } from '@/api/users';
import { CLIENT_ID_KEY, getUser, TOKEN_KEY, USER_INFO_KEY } from '@/lib/utils';
import LoginForm from '@/features/auth/login-form';
import Backdrop from '@/features/backdrop';

const AuthLayer: FunctionComponent = (): ReactElement => {
	const { clientId } = useParams();

	localStorage.setItem(CLIENT_ID_KEY, clientId!);

	const sessionToken = sessionStorage.getItem(TOKEN_KEY);
	const browserUser = getUser();

	const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
	const [isUserReady, setIsUserReady] = useState<boolean>(false);

	const shouldFetchUser = useMemo(
		() => !sessionToken || browserUser.erpId !== clientId,
		[sessionToken, browserUser, clientId]
	);
	const isFetchUserEnabled = useMemo(
		() => !!clientId && shouldFetchUser,
		[clientId, shouldFetchUser]
	);

	useEffect(() => {
		if (sessionToken && clientId === browserUser.erpId) {
			setIsUserReady(true);
		}
	}, [sessionToken, clientId, browserUser]);

	const {
		isSuccess,
		isError,
		error,
		data: authUser,
	} = useQuery({
		queryKey: ['auth_user', clientId],
		queryFn: () => login('', clientId!),
		enabled: isFetchUserEnabled,
		retry: false,
	});

	useEffect(() => {
		if (isSuccess && authUser) {
			const { jwt } = authUser;
			sessionStorage.setItem(TOKEN_KEY, jwt);
			localStorage.setItem(USER_INFO_KEY, JSON.stringify(authUser));

			setIsUserReady(true);
			setShowLoginForm(false);
		}
	}, [isSuccess, authUser]);

	useEffect(() => {
		if (isError && error) {
			const { status } = (error as AxiosError).response || {};
			if (status === 403 || status === 500) {
				setShowLoginForm(true);
			}

			sessionStorage.removeItem(TOKEN_KEY);
			localStorage.removeItem(USER_INFO_KEY);
		}
	}, [isError, error]);

	if (showLoginForm) {
		return (
			<LoginForm
				onSuccess={() => {
					setShowLoginForm(false);
					setIsUserReady(true);
				}}
			/>
		);
	}

	if (!isUserReady) {
		return <Backdrop />;
	}

	return <Outlet />;
};

export default AuthLayer;
