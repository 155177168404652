import { RouteObject } from 'react-router-dom';
import { queryClient } from '@/query/query-client';
import FastBrainTileSelectionIntro from '@/views/pages/fast-brain/tile-selection/intro';
import FastBrainTileSelection from '@/views/pages/fast-brain/tile-selection/index';
import FastBrainTileQuestionsIntro from '@/views/pages/fast-brain/tile-questions/intro';
import FastBrainTileQuestions from '@/views/pages/fast-brain/tile-questions/index';
import FastBrainOutput from '@/views/pages/fast-brain/output';
import { tileQuestionsLoader, tileSelectionLoader } from './loaders';
import FlowGuard from '../../guards/FlowGuard';
import { Flow } from '@/api/users/types';

export const fastBrainRoutes: RouteObject[] = [
	{
		path: 'fast-brain/tile-selection/intro',
		element: <FlowGuard flow={Flow.FAST_BRAIN} element={<FastBrainTileSelectionIntro />} />,
	},
	{
		path: 'fast-brain/tile-selection',
		element: <FlowGuard flow={Flow.FAST_BRAIN} element={<FastBrainTileSelection />} />,
		loader: ({ params: { clientId } }) => tileSelectionLoader(queryClient, clientId as string),
	},
	{
		path: 'fast-brain/tile-questions/intro',
		element: <FlowGuard flow={Flow.FAST_BRAIN} element={<FastBrainTileQuestionsIntro />} />,
	},
	{
		path: 'fast-brain/tile-questions',
		element: <FlowGuard flow={Flow.FAST_BRAIN} element={<FastBrainTileQuestions />} />,
		loader: ({ params: { clientId } }) => tileQuestionsLoader(queryClient, clientId as string),
	},
	{
		path: 'fast-brain/output',
		element: <FlowGuard flow={Flow.FAST_BRAIN} element={<FastBrainOutput />} />,
	},
];
