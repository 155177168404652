import { useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './query/query-client';
import Router from './router/Router';
import './App.scss';

const App = () => {
	const isInspectorOn = import.meta.env.VITE_ENVIRONMENT === 'local';

	useEffect(() => {
		// Dynamically import the inspector and debug layer for Babylon scenes if the inspector is enabled
		if (isInspectorOn) {
			import('@babylonjs/inspector')
				.then(() => import('@babylonjs/core/Debug/debugLayer'))
				.catch((error) => {
					console.error('Failed to load Babylon.js Inspector modules: ', error);
				});
		}
	}, [isInspectorOn]);

	return (
		<QueryClientProvider client={queryClient}>
			<Router />
		</QueryClientProvider>
	);
};

export default App;
