import { RouteObject } from 'react-router-dom';
import RiskAssessmentIntro from '@/views/pages/risk-assessment/intro';
import RiskAssessmentOutput from '@/views/pages/risk-assessment/output';
import RiskAssessmentQuestionnaire from '@/views/pages/risk-assessment/questionnaire';
import FlowGuard from '../guards/FlowGuard';
import { Flow } from '@/api/users/types';

export const riskAssessmentRoutes: RouteObject[] = [
	{
		path: 'risk-assessment/intro',
		element: <FlowGuard flow={Flow.RISK_ASSESSMENT} element={<RiskAssessmentIntro />} />,
	},
	{
		path: 'risk-assessment/questionnaire',
		element: <FlowGuard flow={Flow.RISK_ASSESSMENT} element={<RiskAssessmentQuestionnaire />} />,
	},
	{
		path: 'risk-assessment/output',
		element: <FlowGuard flow={Flow.RISK_ASSESSMENT} element={<RiskAssessmentOutput />} />,
	},
];
