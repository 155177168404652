import AbstractControlInput, {
	AbstractControlInputProps,
} from '../abstract-control-input/AbstractControlInput';

type RadioInputProps = Omit<AbstractControlInputProps, 'type'>;

const RadioInput: React.FC<RadioInputProps> = ({ ...props }) => (
	<AbstractControlInput type="radio" {...props} />
);

export default RadioInput;
