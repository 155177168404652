import { useQueries, useQueryClient } from '@tanstack/react-query';
import { TileGroup, TileGroupSelected } from '@/api/tile-groups/types';
import {
	useSlowBrainTileGroupsOptions,
	useSlowBrainTileGroupsSelectedTilesOptions,
} from '@/query/queries/slow-brain';

interface UseSlowBrainTileSelectionDataProps {
	tileGroupsInitialData: TileGroup[];
	tileGroupsSelectedTilesInitialData: TileGroupSelected[];
}

interface UseSlowBrainTileSelectionData {
	tileGroups: TileGroup[];
	tileGroupsSelectedTiles: TileGroupSelected[];
	invalidateSelectedTiles: () => Promise<void>;
}

export const useSlowBrainTileSelectionData = ({
	tileGroupsInitialData,
	tileGroupsSelectedTilesInitialData,
}: UseSlowBrainTileSelectionDataProps): UseSlowBrainTileSelectionData => {
	const queryClient = useQueryClient();
	const { queryKey: selectedTilesQueryKey } = useSlowBrainTileGroupsSelectedTilesOptions;

	const [{ data: tileGroups }, { data: tileGroupsSelectedTiles }] = useQueries({
		queries: [
			{
				...useSlowBrainTileGroupsOptions,
				initialData: tileGroupsInitialData,
				staleTime: Infinity,
			},
			{
				...useSlowBrainTileGroupsSelectedTilesOptions,
				initialData: tileGroupsSelectedTilesInitialData,
				staleTime: Infinity,
			},
		],
	});

	const invalidateSelectedTiles = () =>
		queryClient.invalidateQueries({ queryKey: [...selectedTilesQueryKey] });

	return {
		tileGroups,
		tileGroupsSelectedTiles,
		invalidateSelectedTiles,
	};
};
