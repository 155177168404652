import { httpClient } from '@/api/http';
import { Portfolio } from './types';
import { Answer } from '../types';
import { portfolioRecommendationAnswers } from './mocks';

export const getPortfolioRecommendationsAnswers = async (): Promise<Answer[]> => {
	const { data } = await httpClient.get<Answer[]>('/flows/recommendation/answers');

	return data;
};

export const getPortfolioRecommendations = async (): Promise<Portfolio[]> => {
	const { data } = await httpClient.get<Portfolio[]>(
		'/flow/risk_assessment/recommended-portfolios'
	);

	return data;
};

export const mockGetPortfolioRecommendationsAnswers = async (): Promise<Answer[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(portfolioRecommendationAnswers), 500);
	});

export const getPortfolioRecommendationsOutput = async (): Promise<void> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(), 500);
	});
