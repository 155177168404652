import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import Markdown from 'react-markdown';
import { Section } from '@/api/questions/types';

interface ItemProps {
	item: Section | null;
	ind: number;
	topAlign?: boolean;
}

const Card: FunctionComponent<ItemProps> = ({ item, ind, topAlign }): ReactElement => {
	const ref = useRef<HTMLLIElement>(null);
	const [elHeight, setElHeight] = useState(0);
	const { scrollY } = useScroll({
		target: ref,
		offset: ['start start', 'end end'],
	});

	useEffect(() => {
		if (!ref.current) return;
		const cardBody = ref.current.querySelector<HTMLDivElement>('.card-body');
		const height = cardBody ? cardBody.offsetHeight + 32 : 0;
		setElHeight(height);
	}, [ref]);

	const initialScrollHeight = 630;
	const gapsHeight = 128;

	const start = initialScrollHeight + ind * elHeight - gapsHeight;
	const end = initialScrollHeight + (ind + 1) * elHeight - gapsHeight;

	const scale = useTransform(scrollY, [start, end], [1, 0.9]);

	return (
		<motion.li
			style={{
				scale,
				...{ '--index': ind + 1 },
				...(item ? {} : { visibility: 'hidden' }),
			}}
			ref={ref}
			className="card"
			id={`card-${ind + 1}`}
		>
			<div className={topAlign ? 'card-body-top-text' : 'card-body'}>
				<h2 className="text-blue-dark">{item?.header}</h2>
				{item?.text?.map((sct) => (
					<p className="text-body-16-md text-body" key={sct}>
						<Markdown>{sct.replace(/^-\s*/, '• ')}</Markdown>
					</p>
				))}
			</div>
		</motion.li>
	);
};

export default Card;
