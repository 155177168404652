import { FunctionComponent, ReactElement, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { SlowBrainTileSelectionLoaderResult } from '@/router/routes/protected/slow-brain';
import { ButtonBack, ButtonNext } from '@/components/ui/button';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import ProgressSteps from '@/components/ui/progress/progress-steps';
import {
	useIsSlowBrainTileSelectionDisabled,
	useSlowBrainOnNextStep,
	useSlowBrainSelectedTiles,
	useSlowBrainStepStatuses,
	useSlowBrainTileGroups,
	useSlowBrainTileSelectionData,
} from '@/features/slow-brain/tile-selection/hooks';
import { SlowBrainTileSelectionStep } from '@/features/slow-brain/types';
import SlowBrainTileRenderer from '@/features/slow-brain/tile-selection/components/slow-brain-tile-renderer';
import StepDescriptionDialog, {
	STEP_DESCRIPTION_DIALOG_CONTENT_MAP,
} from '@/features/slow-brain/tile-selection/components/step-description-dialog';
import { isCurrentFlowFinished } from '@/lib/utils';
import { Flow } from '@/api/users/types';

const SlowBrainTileSelection: FunctionComponent = (): ReactElement => {
	// Extract loader data
	const { tileGroups: tilesInitialData, tileGroupsSelectedTiles: selectedTilesInitialData } =
		useLoaderData() as SlowBrainTileSelectionLoaderResult;

	const isFlowFinished = isCurrentFlowFinished(Flow.SLOW_BRAIN);

	// Define and initialize state
	const [currentStep, setCurrentStep] = useState<SlowBrainTileSelectionStep>('personal_outcomes');
	const [isStepDescriptionOpen, setIsStepDescriptionOpen] = useState<boolean>(true);
	const [isSceneLoaded, setIsSceneLoaded] = useState<boolean>(false);

	// Get tile selection data: tile groups and selected tiles
	const { tileGroups, tileGroupsSelectedTiles, invalidateSelectedTiles } =
		useSlowBrainTileSelectionData({
			tileGroupsInitialData: tilesInitialData,
			tileGroupsSelectedTilesInitialData: selectedTilesInitialData,
		});

	// Get selected tiles by tile selection step and selected tiles for current step
	const { stepSelectedTiles, currentStepSelectedTiles } = useSlowBrainSelectedTiles({
		currentStep,
		// TODO: Remove short circuiting when empty session response become consistent
		tileGroupsSelectedTiles: tileGroupsSelectedTiles || [],
	});

	// Get tile groups by tile selection step and tile group for current step
	const { stepTileGroups, currentStepTileGroup } = useSlowBrainTileGroups({
		currentStep,
		tileGroups,
	});

	// Get statuses for each step
	const {
		personal_outcomes: personalStepStatus,
		money_outcomes: moneyOutcomesStepStatus,
		plan_vulnerabilities: planVulnerabilitiesStepStatus,
		top_5_selection: top5SelectionStatus,
	} = useSlowBrainStepStatuses({
		currentStep,
		stepSelectedTiles,
		tileGroupsSelectedTiles,
	});

	// Get next step function that will be executed when user submit tile selection
	const { onNextStep: onNextStepFn } = useSlowBrainOnNextStep({
		currentStep,
		stepTileGroups,
	});

	const navigate = useNavigate();
	const setCurrentStepFn = () => {
		if (currentStep === 'personal_outcomes') setCurrentStep('money_outcomes');
		if (currentStep === 'money_outcomes') setCurrentStep('plan_vulnerabilities');
		if (currentStep === 'plan_vulnerabilities') setCurrentStep('top_5_selection');
		if (currentStep === 'top_5_selection') navigate('../slow-brain/tile-questions/intro');
	};

	const { mutate: onNextStep, isPending: isSubmitting } = useMutation({
		mutationFn: () => onNextStepFn(),
		onSuccess: () => {
			setCurrentStepFn();
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			invalidateSelectedTiles();
			setIsSceneLoaded(false);
			setIsStepDescriptionOpen(true);
		},
	});

	// Compute if `on next step` is disabled
	const isDisabled = useIsSlowBrainTileSelectionDisabled({ currentStep, isLoading: isSubmitting });

	const navigateBack = () => {
		let previousStep: SlowBrainTileSelectionStep | 'intro' = 'intro';
		if (currentStep === 'money_outcomes') previousStep = 'personal_outcomes';
		if (currentStep === 'plan_vulnerabilities') previousStep = 'money_outcomes';
		if (currentStep === 'top_5_selection') previousStep = 'plan_vulnerabilities';

		if (previousStep === 'intro') {
			navigate('/slow-brain/tile-selection/intro', { replace: true });
			return;
		}

		setIsSceneLoaded(false);
		setIsStepDescriptionOpen(true);
		setCurrentStep(previousStep);
	};

	const controls = (
		<div className="flex flex-row items-center justify-center gap-8">
			<ButtonBack onClick={navigateBack} />
			<ButtonNext
				onClick={() => {
					if (!isFlowFinished) onNextStep();
					else setCurrentStepFn();
				}}
				disabled={isDisabled}
			>
				Continue
			</ButtonNext>
		</div>
	);

	const onStepClick = (step: SlowBrainTileSelectionStep) => {
		if (currentStep === step) return;
		setCurrentStep(step);
		setIsSceneLoaded(false);
		setIsStepDescriptionOpen(true);
	};

	return (
		<div className="flex h-full flex-col bg-neutral-page">
			<Header titleText="Slow Brain" titleLabel="Tile Selection" />
			<ProgressSteps
				steps={[
					{
						status: personalStepStatus,
						serialNumber: 1,
						name: 'Personal Outcomes',
						onClick: () => onStepClick('personal_outcomes'),
					},
					{
						status: moneyOutcomesStepStatus,
						serialNumber: 2,
						name: 'Money Outcomes',
						onClick: () => onStepClick('money_outcomes'),
					},
					{
						status: planVulnerabilitiesStepStatus,
						serialNumber: 3,
						name: 'Plan Vulnerabilities',
						onClick: () => onStepClick('plan_vulnerabilities'),
					},
					{
						status: top5SelectionStatus,
						serialNumber: 4,
						name: 'Top 5 Selection',
						onClick: () => onStepClick('top_5_selection'),
					},
				]}
			/>

			<main className="flex-1 overflow-hidden">
				{isSceneLoaded && (
					<SlowBrainTileRenderer
						currentStep={currentStep}
						tilesGroup={currentStepTileGroup || []}
						selectedTilesGroup={currentStepSelectedTiles || []}
					/>
				)}
			</main>

			<Footer withBoxShadow={true} itemsPosition="center" size="lg" controls={controls} />

			<StepDescriptionDialog
				open={isStepDescriptionOpen}
				onClose={() => {
					setIsSceneLoaded(true);
					setIsStepDescriptionOpen(false);
				}}
				{...STEP_DESCRIPTION_DIALOG_CONTENT_MAP[currentStep]}
			/>
		</div>
	);
};

export default SlowBrainTileSelection;
