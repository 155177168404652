import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ConcurrentLogo from '@/assets/logos/logo-white.png';
import { getClientId, getUser } from '@/lib/utils';

interface HeaderFlowProps {
	titleText: ReactNode;
	titleLabel?: ReactNode;
}

const HeaderFlow: FunctionComponent<HeaderFlowProps> = ({
	titleText,
	titleLabel,
}): ReactElement => {
	const clientId = getClientId();
	const userInfo = getUser();
	return (
		<div className="header w-full">
			<div className="container flex flex-col justify-between gap-3 pb-4 pt-3">
				<div className="flex items-center justify-between px-10">
					<Link to={`/${clientId}`}>
						<img src={ConcurrentLogo} alt="Logo" width={133} height={40} />
					</Link>
					{userInfo && (
						<div className="text-label-m font-medium text-white">{userInfo?.clientName}</div>
					)}
				</div>

				<div className="flex justify-between px-20">
					<div className="flex flex-col">
						{titleLabel && (
							<p className="font-caveat text-cover-script text-blue-500">{titleLabel}</p>
						)}
						<h1 className="text-cover-title text-white">{titleText}</h1>
					</div>
					{/* <div className="flex items-center">
					<RiInformationLine size={18} color="#ffffff" className="mr-2" />
					<span className="text-white">Learn More</span>
				</div> */}
				</div>
			</div>
		</div>
	);
};

export default HeaderFlow;
