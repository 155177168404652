import { forwardRef, KeyboardEvent, ReactElement } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import WrapperInput from '../../wrapper-input';
import { TextInputProps } from '../text-input.types';
import '../text-input.scss';

interface TextFieldProps extends TextInputProps {
	onPressEnter?: () => void;
	showInfo?: boolean;
}

const TextField = forwardRef<HTMLTextAreaElement, TextFieldProps>(
	(
		{ id, name, value, onChange, onPressEnter, placeholder, showInfo = true, error },
		ref
	): ReactElement => {
		const onEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				onPressEnter?.();
			}
		};

		return (
			<div className="text-input__wrapper">
				<WrapperInput error={error}>
					<ReactTextareaAutosize
						id={id}
						name={name}
						value={value}
						onChange={(e) => onChange?.(e.target.value)}
						placeholder={placeholder}
						className="text-input"
						onKeyDown={(e) => onEnter(e)}
						ref={ref}
					/>
				</WrapperInput>
				{showInfo && (
					<small className="text-input__info">
						<strong>Shift &#8679; + Enter &crarr;</strong> to make a line break
					</small>
				)}
			</div>
		);
	}
);

TextField.displayName = 'Text Field';

export default TextField;
