import { FunctionComponent, ReactElement, useEffect, useMemo, useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { FastBrainTileQuestionsLoaderResult } from '@/router/routes/protected/fast-brain';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import QuestionGroup from '@/components/feature/questions/question-group/QuestionGroup';
import FastBrainSelectedTiles from '@/components/feature/fast-brain-selected-tiles';
import {
	useFastBrainQuestionsData,
	useFastBrainQuestionsState,
} from '@/features/fast-brain/tile-questions';
import { useFastBrainAnswerQuestions } from '@/features/fast-brain/tile-questions/useFastBrainAnswerQuestions';
import { isCurrentFlowFinished } from '@/lib/utils';
import { Flow } from '@/api/users/types';

const FastBrainTileQuestions: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();

	const scrollElementRef = useRef<HTMLDivElement | null>(null);
	const isFlowFinished = isCurrentFlowFinished(Flow.FAST_BRAIN);

	// Extract loader data
	const {
		tileGroupSelectedTiles: tileGroupSelectedTilesInitialData,
		questions: questionsInitialData,
		answers: answersInitialData,
	} = useLoaderData() as FastBrainTileQuestionsLoaderResult;

	// Api data
	const { selectedTiles, questions, answers, invalidateAnswers } = useFastBrainQuestionsData({
		tileGroupSelectedTilesInitialData,
		questionsInitialData,
		answersInitialData,
	});

	// State data
	const { questionnaire, currentQuestion, onQuestionChange, onQuestionAnswered } =
		useFastBrainQuestionsState(questions, answers);

	// If no tiles are selected, navigate to tile selection intro page
	useEffect(() => {
		// TODO: Remove optional chaining when empty session response become consistent
		if (!selectedTiles?.length) navigate('../fast-brain/tile-selection/intro');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTiles]);

	const { mutate: onSubmit, isPending: isSubmitting } = useFastBrainAnswerQuestions({
		questionnaire,
		onSuccess: () => {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			invalidateAnswers();
			navigate('../fast-brain/output');
		},
	});

	const isDisabled = useMemo(() => {
		if (isSubmitting) return true;
		return !Object.entries(questionnaire).every(([, answer]) => !!answer.length);
	}, [questionnaire, isSubmitting]);

	return (
		<div className="flex h-full flex-col overflow-x-hidden bg-neutral-page">
			<Header titleText="Fast Brain" titleLabel="Tile Questions" />
			{/* TODO: Remove optional chaining and short circuiting once empty session response become consistent */}
			<div className="flex justify-center">
				<FastBrainSelectedTiles tiles={selectedTiles?.map(({ text }) => ({ text })) || []} />
			</div>
			<main
				className="h-full snap-y snap-mandatory overflow-y-scroll scroll-smooth px-4"
				ref={scrollElementRef}
			>
				<QuestionGroup
					questions={questions}
					answers={questionnaire}
					currentQuestion={currentQuestion}
					setCurrentQuestion={(question) => onQuestionChange(question)}
					onChange={(questionId, answer) => onQuestionAnswered(questionId, answer)}
					onNextStep={{
						label: 'Continue',
						callback: () => {
							if (!isFlowFinished) onSubmit();
							else navigate('../fast-brain/output');
						},
						disabled: isDisabled,
					}}
					scrollElementRef={scrollElementRef}
				/>
			</main>
			<Footer withBoxShadow={false} itemsPosition="start" size="md" />
		</div>
	);
};

export default FastBrainTileQuestions;
