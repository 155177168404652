import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const PortfolioRecommendationsIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		heading="Portfolio Considerations"
		link={{
			to: '../portfolio-considerations/questionnaire',
			text: 'View Portfolio Considerations',
		}}
	>
		We have now uncovered an appropriate “Asset Allocation” for your portfolio. The next step is to
		understand what is the ideal portfolio, meaning the actual holdings within the portfolio, that
		aligns with your recommended asset allocation
	</IntroLayout>
);

export default PortfolioRecommendationsIntro;
