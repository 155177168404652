import { useDebouncedCallback } from 'use-debounce';
import { MutableRefObject, useCallback, useEffect } from 'react';

type useScrollProps = {
	questionRefs: MutableRefObject<(HTMLDivElement | null)[]>;
	scrollElementRef: MutableRefObject<HTMLDivElement | null>;
	currentQuestion: number;
	setCurrentQuestion: (question: number) => void;
};

const useQuestionGroupScroll = ({
	questionRefs,
	scrollElementRef,
	currentQuestion,
	setCurrentQuestion,
}: useScrollProps) => {
	const getVisibleIndex = useCallback(() => {
		let visibleIndex = null;
		if (questionRefs.current && questionRefs.current.length) {
			// eslint-disable-next-line no-restricted-syntax
			for (const [index, questionRef] of questionRefs.current.entries()) {
				const parent = scrollElementRef.current?.getBoundingClientRect();
				const child = questionRef?.getBoundingClientRect();

				if (parent && child) {
					const isFullyWithinParent = child.top >= parent.top && child.bottom <= parent.bottom;
					const isIntersectingParent = child.bottom > parent.top && child.top < parent.bottom;

					if (isFullyWithinParent || isIntersectingParent) {
						visibleIndex = index;
						break;
					}
				}
			}
		}

		return visibleIndex;
	}, [questionRefs, scrollElementRef]);

	const updateCurrentQuestion = () => {
		const visibleIndex = getVisibleIndex();
		if (visibleIndex !== null) setCurrentQuestion(visibleIndex);
	};

	const updateCurrentQuestionDebounced = useDebouncedCallback(updateCurrentQuestion, 300);

	useEffect(() => {
		scrollElementRef.current?.addEventListener('scroll', updateCurrentQuestionDebounced);
		return () => {
			window.removeEventListener('scroll', updateCurrentQuestionDebounced);
		};
	}, [scrollElementRef, updateCurrentQuestionDebounced]);

	useEffect(() => {
		const visibleIndex = getVisibleIndex();

		if (visibleIndex !== null && visibleIndex !== currentQuestion) {
			questionRefs.current[currentQuestion]?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [currentQuestion, getVisibleIndex, questionRefs]);
};

export default useQuestionGroupScroll;
