import { FunctionComponent, ReactElement, useMemo } from 'react';
import { Portfolio } from '@/api/answers/portfolio-recommendations/types';
import { classNames } from '@/lib/utils';

interface PortfolioRecommendationsProps {
	portfolios: Portfolio[];
	classes?: string[];
}

const PortfolioRecommendations: FunctionComponent<PortfolioRecommendationsProps> = ({
	portfolios,
	classes = [],
}): ReactElement | null => {
	const showPortfolios = useMemo(() => !!portfolios.length, [portfolios]);

	return showPortfolios ? (
		<div
			className={classNames(
				'flex',
				'flex-col',
				'gap-6',
				'rounded-md',
				'bg-white',
				'p-8',
				'shadow',
				'print:break-inside-avoid',
				'print:bg-inherit',
				'print:p-0',
				'print:shadow-none',
				...classes
			)}
		>
			<div className="flex flex-col gap-4">
				<h2 className="text-heading-24 text-blue-dark">Portfolio Recommendations</h2>
				<div className="text-body-16-md leading-normal">
					{portfolios.map(({ id, name }) => (
						<div key={id} className="border-b border-b-neutral-300 border-opacity-30 py-4">
							<p>{name}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	) : null;
};

export default PortfolioRecommendations;
