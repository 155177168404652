import { forwardRef, ReactElement } from 'react';
import { RadioInput } from '@/components/ui/inputs';
import QuestionAbstract, { QuestionAbstractProps } from './QuestionAbstract';

export interface QuestionSingleChoiceProps extends Omit<QuestionAbstractProps, 'children'> {
	answer?: string;
	onChange: (questionId: string, answer: string, type: string) => void;
}

export const QuestionSingleChoice = forwardRef<HTMLDivElement, QuestionSingleChoiceProps>(
	({ question, answer, onChange, ...questionAbstractProps }, ref): ReactElement => (
		<QuestionAbstract {...{ ...questionAbstractProps, question }} ref={ref}>
			{question.choices?.map((choice, index) => (
				<RadioInput
					key={index}
					id={`${question.id}-question-${index}`}
					name={`${question.id}-question`}
					label={choice.text}
					value={choice.id}
					onChange={(answer) => onChange(question.id, answer, question.type)}
					checked={answer === choice.id}
				/>
			))}
		</QuestionAbstract>
	)
);

QuestionSingleChoice.displayName = 'Question Single Choice';

export default QuestionSingleChoice;
