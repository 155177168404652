import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getNextFlowEntryPoint } from '@/router/utils/flows';
import { getSlowBrainOutput } from '@/api/questions/slow-brain';
import { Output } from '@/api/questions/types';
import { Flow } from '@/api/users/types';
import { useSlowBrainPickedTilesOptions } from '@/query/queries/slow-brain';
import PageLayout from '@/views/layouts/page-layout';
import Footer from '@/components/ui/footer';
import LinkButton from '@/components/ui/link-button';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import OutputLoader from '@/components/feature/output-loader';
import SlowBrainSelectedTiles from '@/components/feature/slow-brain-selected-tiles/SlowBrainSelectedTiles';
import StackingCards from '@/components/feature/stacking-cards';
import { SuccessBadge } from '@/components/feature/badges';
import { Print } from '@/assets/icons/Print';

const SlowBrainOutput: FunctionComponent = (): ReactElement => {
	const { clientId } = useParams();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFinished, setIsFinished] = useState<boolean>(false);
	const [outputData, setOutputData] = useState<Output>();

	const { data: slowBrainTiles } = useQuery({
		...useSlowBrainPickedTilesOptions,
	});

	const nextFlowRedirect = useMemo(() => {
		const nextFlowEntryPoint = getNextFlowEntryPoint(Flow.SLOW_BRAIN);
		return nextFlowEntryPoint ? `/${clientId}/${nextFlowEntryPoint}` : undefined;
	}, [clientId]);

	const { mutate } = useMutation({
		mutationFn: () => getSlowBrainOutput(),
		onSuccess: (data) => {
			setOutputData(data);
			document.documentElement.style.setProperty('--cards', `${data.sections.length}`);
			setIsFinished(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 2500);
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => mutate(), []);

	const printButton: ReactNode = useMemo(
		() =>
			!isLoading ? (
				<ButtonPrimary className="print:hidden" icon={<Print />} onClick={() => window.print()}>
					Save as PDF
				</ButtonPrimary>
			) : undefined,
		[isLoading]
	);

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			headerControls={printButton}
		>
			{isLoading ? (
				<OutputLoader completed={isFinished} />
			) : (
				<div className="m-auto flex max-w-[872px] flex-col gap-12 p-12">
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-6">
							<SuccessBadge />
							<h1 className="text-results-title">Slow Brain</h1>
						</div>
						<p className="text-body-18-md">
							Thank you for working through your Slow Brain Exercise. Here are the recommended next
							steps to help you implement your investment strategy and stay on track with your
							financial goals:
						</p>
					</div>
					<div className="flex flex-col">
						<h3 className="mb-5 text-heading-24 text-body opacity-70">
							Here&apos;s an overview of your tile selection:
						</h3>
						<div className="flex flex-row flex-wrap">
							<SlowBrainSelectedTiles
								tiles={
									slowBrainTiles?.map((tile) => ({
										tile,
										disabled: false,
										isCompleted: false,
										isSelected: false,
									})) || []
								}
								classes={['opacity-100', 'cursor-default']}
							/>
						</div>
					</div>
					<StackingCards sections={outputData?.sections} />
					<div className="inline self-end print:hidden">
						{nextFlowRedirect && (
							<LinkButton to={nextFlowRedirect} title="Continue">
								Continue
							</LinkButton>
						)}
					</div>
				</div>
			)}
		</PageLayout>
	);
};

export default SlowBrainOutput;
