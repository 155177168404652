import { forwardRef, ReactElement } from 'react';
import WrapperInput from '../../wrapper-input';
import { TextInputProps } from '../text-input.types';
import '../text-input.scss';

const PasswordField = forwardRef<HTMLInputElement, TextInputProps>(
	({ id, name, value, onChange, placeholder, error }, ref): ReactElement => (
		<div className="text-input__wrapper">
			<WrapperInput error={error}>
				<input
					ref={ref}
					id={id}
					type="password"
					name={name}
					value={value}
					onChange={(e) => onChange?.(e.target.value)}
					placeholder={placeholder}
					className="text-input"
				/>
			</WrapperInput>
		</div>
	)
);

PasswordField.displayName = 'Password Field';

export default PasswordField;
