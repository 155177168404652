import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { TOKEN_KEY } from '@/lib/utils';

const { VITE_BASE_API_URL, VITE_OCP_APIM_SUBSCRIPTION_KEY } = import.meta.env;

const BASE_API_URL = VITE_BASE_API_URL as string;
const OCP_APIM_SUBSCRIPTION_KEY = VITE_OCP_APIM_SUBSCRIPTION_KEY as string;

export const httpClient = axios.create({
	baseURL: BASE_API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': OCP_APIM_SUBSCRIPTION_KEY,
	},
});

httpClient.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const token = sessionStorage.getItem(TOKEN_KEY);

		if (token) {
			// eslint-disable-next-line no-param-reassign
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error: AxiosError) => Promise.reject(error)
);
