import React from 'react';

interface WrapperInputProps {
	children: React.ReactNode;
	error?: React.ReactNode;
}

const WrapperInput: React.FC<WrapperInputProps> = ({ children, error }): React.ReactElement => (
	<div className="flex flex-col gap-2">
		{children}
		{error && <span className="text-label-m text-error">{error}</span>}
	</div>
);

export default WrapperInput;
