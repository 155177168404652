import { httpClient } from '@/api/http';
import { AuthUser, User } from './types';

export const getAuthUser = async (clientId: string): Promise<User> => {
	const { data } = await httpClient.get<User>(`/clients/erp/${clientId}`);

	return data;
};

export const login = async (password: string, erpId: string): Promise<AuthUser> => {
	const { data } = await httpClient.post<AuthUser>(`/authorize-client`, { password, erpId });

	return data;
};
