import { FunctionComponent, ReactElement } from 'react';

const Backdrop: FunctionComponent = (): ReactElement => (
	<div className="fixed inset-0">
		<div className="absolute inset-0 bg-black/20"></div>
		<div className="relative z-10 flex h-full w-full items-center justify-center">
			<div
				className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-dark border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
				role="status"
			>
				<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
					Loading...
				</span>
			</div>
		</div>
	</div>
);

export default Backdrop;
