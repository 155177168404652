export interface User {
	id: string;
	erpId: string;
	clientName: string;
	clientEmail: string;
	ownerId: string | null;
}

export enum Flow {
	FAST_BRAIN = 'fast_brain',
	SLOW_BRAIN = 'slow_brain',
	RISK_ASSESSMENT = 'risk_assessment',
	PORTFOLIO_RECOMMENDATIONS = 'recommendation',
}

export interface ClientFlows {
	flowId: number;
	flowName: Flow;
	flowOrder: number;
	status: string;
}

export interface AuthUser {
	id: number;
	erpId: string;
	jwt: string;
	clientName: string;
	clientEmail: string;
	clientFlows: ClientFlows[];
}
