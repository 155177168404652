import { FunctionComponent, ReactElement, ReactNode, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ID_KEY, USER_INFO_KEY } from '@/lib/utils';
import { httpClient } from './http';

const NetworkInterceptor: FunctionComponent<{ children: ReactNode }> = ({
	children,
}): ReactElement => {
	const navigate = useNavigate();

	useEffect(() => {
		const responseInterceptor = httpClient.interceptors.response.use(
			(response) => response,
			(error: AxiosError) => {
				if (error.response?.status === 401) {
					localStorage.removeItem(CLIENT_ID_KEY as string);
					localStorage.removeItem(USER_INFO_KEY as string);
					navigate('/unauthorized', { replace: true });
				}

				return Promise.reject(error);
			}
		);

		return () => {
			httpClient.interceptors.response.eject(responseInterceptor);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <>{children}</>;
};

export default NetworkInterceptor;
