import { FormEvent, FunctionComponent, ReactElement, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { motion as m } from 'framer-motion';
import { PasswordField } from '@/components/ui/inputs';
import { ButtonNext } from '@/components/ui/button';
import { login } from '@/api/users';
import { TOKEN_KEY, USER_INFO_KEY } from '@/lib/utils';

interface LoginFormProps {
	onSuccess: () => void;
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ onSuccess }): ReactElement => {
	const { clientId } = useParams();
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<string>();

	const { mutate, isPending: isLoading } = useMutation({
		mutationFn: ({ password = '', erpId }: { password: string; erpId: string }) =>
			login(password, erpId),
		onSuccess: (authUser) => {
			const { jwt } = authUser;

			localStorage.setItem(USER_INFO_KEY, JSON.stringify(authUser));
			sessionStorage.setItem(TOKEN_KEY, jwt);
			onSuccess();
		},
		onError: () => {
			setError('Invalid Password.');
		},
	});

	const isSubmitDisabled = useMemo(() => !password.length || isLoading, [isLoading, password]);

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();
		mutate({ password, erpId: clientId || '' });
	};

	const animationVariants = {
		start: { opacity: 0, y: -100 },
		end: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	};

	return (
		<main className="flex min-h-[100vh] items-center justify-center bg-blue-dark p-6">
			<m.div
				className="w-full max-w-[424px] rounded-lg bg-white p-8"
				initial="start"
				animate="end"
				variants={animationVariants}
			>
				<h1 className="h-9 text-heading-24 text-blue-dark">Password required</h1>
				<p className="mb-6 mt-4 text-body-16-md">Please enter your password to continue.</p>
				<form className="flex flex-col gap-6" onSubmit={onSubmit}>
					<PasswordField
						id="password"
						name="password"
						placeholder="Enter password"
						error={error}
						value={password}
						onChange={(password) => setPassword(password)}
					/>
					<ButtonNext
						type="submit"
						size="lg"
						className="w-full justify-center"
						disabled={isSubmitDisabled}
					>
						Continue
					</ButtonNext>
				</form>
			</m.div>
		</main>
	);
};

export default LoginForm;
