import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getNextFlowEntryPoint } from '@/router/utils/flows';
import { getRiskAssessmentOutput } from '@/api/questions/risk-assessment';
import { Flow } from '@/api/users/types';
import { Output } from '@/api/questions/types';
import PageLayout from '@/views/layouts/page-layout';
import Footer from '@/components/ui/footer';
import LinkButton from '@/components/ui/link-button';
import { SuccessBadge } from '@/components/feature/badges';
import OutputLoader from '@/components/feature/output-loader';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import StackingCards from '@/components/feature/stacking-cards';
import { Print } from '@/assets/icons/Print';
import '@/scss/_output.scss';

const RiskAssessmentOutput: FunctionComponent = (): ReactElement => {
	const { clientId } = useParams();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFinished, setIsFinished] = useState<boolean>(false);
	const [outputData, setOutputData] = useState<Output>();

	const nextFlowRedirect = useMemo(() => {
		const nextFlowEntryPoint = getNextFlowEntryPoint(Flow.RISK_ASSESSMENT);
		return nextFlowEntryPoint ? `/${clientId}/${nextFlowEntryPoint}` : undefined;
	}, [clientId]);

	const { mutate } = useMutation({
		mutationFn: () => getRiskAssessmentOutput(),
		onSuccess: (data) => {
			setOutputData(data);
			document.documentElement.style.setProperty('--cards', `${data.sections.length}`);
			setIsFinished(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 2500);
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => mutate(), []);

	const printButton: ReactNode = useMemo(
		() =>
			!isLoading ? (
				<ButtonPrimary className="print:hidden" icon={<Print />} onClick={() => window.print()}>
					Save as PDF
				</ButtonPrimary>
			) : undefined,
		[isLoading]
	);

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			headerControls={printButton}
		>
			{isLoading ? (
				<OutputLoader completed={isFinished} />
			) : (
				<div className="m-auto flex max-w-[872px] flex-col gap-12 p-12">
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-6">
							<SuccessBadge />
							<h1 className="text-results-title">Risk Assessment Questionnaire</h1>
						</div>
						<p className="text-body-18-md">
							Thank you for working through your Risk Assessment Questionnaire. Here are the
							recommended next steps to help you implement your investment strategy and stay on
							track with your financial goals:
						</p>
					</div>
					<StackingCards sections={outputData?.sections} topAlign={true} />
					<div className="inline self-end print:hidden">
						{nextFlowRedirect && (
							<LinkButton to={nextFlowRedirect} title="Continue">
								Continue
							</LinkButton>
						)}
					</div>
				</div>
			)}
		</PageLayout>
	);
};

export default RiskAssessmentOutput;
