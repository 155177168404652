import { Flow } from '@/api/users/types';
import { getUser } from '@/lib/utils';

const FLOW_ENTRY_POINTS: Record<Flow, string> = {
	[Flow.FAST_BRAIN]: 'fast-brain/tile-selection/intro',
	[Flow.SLOW_BRAIN]: 'slow-brain/tile-selection/intro',
	[Flow.RISK_ASSESSMENT]: 'risk-assessment/intro',
	[Flow.PORTFOLIO_RECOMMENDATIONS]: 'portfolio-considerations/intro',
};

export const getStartingFlowEntryPoint = (): string | undefined => {
	const { clientFlows } = getUser();
	if (clientFlows.length === 0) {
		return undefined;
	}

	const { flowName } = clientFlows.reduce((lowest, current) =>
		current.flowOrder < lowest.flowOrder ? current : lowest
	);

	return FLOW_ENTRY_POINTS[flowName];
};

export const getNextFlowEntryPoint = (currentFlow: Flow): string | undefined => {
	const { clientFlows } = getUser();
	const { flowOrder: currentFlowOrder } = clientFlows.find((f) => f.flowName === currentFlow) || {};

	if (typeof currentFlowOrder !== 'number') {
		return undefined;
	}

	const nextFlow = clientFlows.find((flow) => flow.flowOrder > currentFlowOrder);
	const { flowName: nextFlowName } = nextFlow || {};

	return nextFlowName ? FLOW_ENTRY_POINTS[nextFlowName] : undefined;
};
