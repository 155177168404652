import { FunctionComponent, ReactElement } from 'react';
import { Section } from '@/api/questions/types';
import Card from './Card';
import '@/scss/_output.scss';

interface StackingCardProps {
	sections: Section[] | undefined;
	topAlign?: boolean;
}

const StackingCards: FunctionComponent<StackingCardProps> = ({
	sections,
	topAlign,
}): ReactElement => {
	if (!sections) return <></>;

	return (
		<div className="cards-container">
			<ul id="cards">
				{sections.map((sc, i) => (
					<Card key={sc.header} item={sc} ind={i} topAlign={topAlign} />
				))}
				<Card ind={sections.length} item={null} topAlign={topAlign} />
			</ul>
		</div>
	);
};

export default StackingCards;
