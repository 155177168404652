import { RouteObject } from 'react-router-dom';
import { queryClient } from '@/query/query-client';
import SlowBrainTileQuestionsIntro from '@/views/pages/slow-brain/tile-questions/intro';
import SlowBrainTileQuestions from '@/views/pages/slow-brain/tile-questions/index';
import SlowBrainOutput from '@/views/pages/slow-brain/output';
import SlowBrainTileSelectionIntro from '@/views/pages/slow-brain/tile-selection/intro';
import SlowBrainTileSelection from '@/views/pages/slow-brain/tile-selection/index';
import { tileQuestionsLoader, tileSelectionLoader } from './loaders';
import FlowGuard from '../../guards/FlowGuard';
import { Flow } from '@/api/users/types';

export const slowBrainRoutes: RouteObject[] = [
	{
		path: 'slow-brain/tile-selection/intro',
		element: <FlowGuard flow={Flow.SLOW_BRAIN} element={<SlowBrainTileSelectionIntro />} />,
	},
	{
		path: 'slow-brain/tile-selection',
		element: <FlowGuard flow={Flow.SLOW_BRAIN} element={<SlowBrainTileSelection />} />,
		loader: ({ params: { clientId } }) => tileSelectionLoader(queryClient, clientId as string),
	},
	{
		path: 'slow-brain/tile-questions/intro',
		element: <FlowGuard flow={Flow.SLOW_BRAIN} element={<SlowBrainTileQuestionsIntro />} />,
	},
	{
		path: 'slow-brain/tile-questions',
		element: <FlowGuard flow={Flow.SLOW_BRAIN} element={<SlowBrainTileQuestions />} />,
		loader: ({ params: { clientId } }) => tileQuestionsLoader(queryClient, clientId as string),
	},
	{
		path: 'slow-brain/output',
		element: <FlowGuard flow={Flow.SLOW_BRAIN} element={<SlowBrainOutput />} />,
	},
];
