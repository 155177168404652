import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { redirect } from 'react-router-dom';
import { SlowBrainTileSelected, TileGroup, TileGroupSelected } from '@/api/tile-groups/types';
import {
	useSlowBrainAnswersOptions,
	useSlowBrainPickedTilesOptions,
	useSlowBrainTileGroupsOptions,
	useSlowBrainTileGroupsSelectedTilesOptions,
} from '@/query/queries/slow-brain';
import { Answer } from '@/api/answers/types';
import { CLIENT_ID_KEY } from '@/lib/utils';

export interface SlowBrainTileSelectionLoaderResult {
	tileGroups: TileGroup[];
	tileGroupsSelectedTiles: TileGroupSelected[];
}

export const tileSelectionLoader = async (
	queryClient: QueryClient,
	clientId: string
): Promise<SlowBrainTileSelectionLoaderResult | Response> => {
	localStorage.setItem(CLIENT_ID_KEY as string, clientId);

	try {
		const [tileGroups, tileGroupsSelectedTiles] = await Promise.all([
			queryClient.fetchQuery(useSlowBrainTileGroupsOptions),
			queryClient.fetchQuery(useSlowBrainTileGroupsSelectedTilesOptions),
		]);

		return { tileGroups, tileGroupsSelectedTiles };
	} catch (error) {
		if ((error as AxiosError).response?.status === 401) {
			return redirect('/unauthorized');
		}

		throw error;
	}
};

export interface SlowBrainTileQuestionsLoaderResult {
	tileGroupSelectedTiles: SlowBrainTileSelected[];
	answers: Answer[];
}

export const tileQuestionsLoader = async (
	queryClient: QueryClient,
	clientId: string
): Promise<SlowBrainTileQuestionsLoaderResult | Response> => {
	localStorage.setItem(CLIENT_ID_KEY as string, clientId);

	try {
		const [selectedTiles, answers] = await Promise.all([
			queryClient.fetchQuery(useSlowBrainPickedTilesOptions),
			queryClient.fetchQuery(useSlowBrainAnswersOptions),
		]);

		return { tileGroupSelectedTiles: selectedTiles, answers };
	} catch (error) {
		if ((error as AxiosError).response?.status === 401) {
			return redirect('/unauthorized');
		}
		throw error;
	}
};
